var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-staff-list-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"admin-staff-smart-list",staticClass:"mt-4",attrs:{"paginated":"","endpoint":_vm.staffEndpoint},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var staffMember = ref.item;
return [_c('div',{staticClass:"staff-list-container"},[_c('v-card',{staticClass:"my-2 mx-2 post-card",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.handleClick(staffMember)}}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":staffMember.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(staffMember.full_name)+" "),(staffMember.is_admin)?_c('v-chip',{staticClass:"px-2 ml-1",attrs:{"x-small":"","color":"red lighten-4"}},[_vm._v(" Admin ")]):_vm._e(),(staffMember.is_driver)?_c('v-chip',{staticClass:"px-2 ml-1",attrs:{"x-small":"","color":"blue lighten-4"}},[_vm._v(" Driver ")]):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(staffMember.username)+" ")])],1),_c('v-list-item-icon',{staticClass:"align-self-center mr-n3"},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)]}}])})],1)],1),_c('intract-create-edit-sheet',{key:"staff-create-sheet",staticClass:"staff-create-sheet",attrs:{"persistent":"","title":"Create Staff","visible":_vm.createStaffSheet.visible,"fields":_vm.staffCreationForm,"submit-button-text":"Create","endpoint":_vm.endpoints.staffViewSet,"edit-id":_vm.createStaffSheet.editId,"data-object":_vm.createStaffSheet.obj,"create-success-message":_vm.createStaffSheet.createSuccessMessage},on:{"close":function($event){_vm.createStaffSheet.visible = false;
      _vm.createStaffSheet.editId = null;
      _vm.createStaffSheet.obj.image = null;},"objectCreated":_vm.staffCreated,"updateObject":function (obj) {
      obj.is_faculty = !obj.is_driver
      _vm.createStaffSheet.obj = obj
    }},scopedSlots:_vm._u([{key:"custom-field--image",fn:function(ref){
    var obj = ref.obj;
return [_c('v-col',{staticClass:"text-center"},[_c('div',[_c('v-avatar',{staticClass:"mb-2",attrs:{"size":"100px"}},[(!obj.image)?_c('v-img',{attrs:{"src":require("../../../assets/images/default.jpg")}}):_c('v-img',{attrs:{"src":obj.image.document}})],1)],1),_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.profileImageField.$refs.input.click()}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-image")]),(!obj.image)?_c('span',[_vm._v("Add Image")]):_c('span',[_vm._v("Edit Image")])],1),_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"profileImageField",staticClass:"ma-0 pa-0",attrs:{"accept":"image/*"},on:{"change":function (file) { return _vm.addProfileImage(file, obj); }}})],1)]}},{key:"custom-field--username",fn:function(ref){
    var obj = ref.obj;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":_vm.usernameError,"maxlength":"30","hide-details":"auto","rules":_vm.usernameRules},on:{"input":function($event){return _vm.checkUsernameExists(obj.username)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Username"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]},proxy:true}],null,true),model:{value:(obj.username),callback:function ($$v) {_vm.$set(obj, "username", $$v)},expression:"obj.username"}}),(obj.phone || obj.full_name)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Suggestions: "),(obj.phone && obj.username != obj.phone)?_c('v-chip',{staticClass:"mx-1",on:{"click":function($event){obj.username = obj.phone;
            _vm.checkUsernameExists(obj.username);}}},[_vm._v(_vm._s(obj.phone))]):_vm._e(),(
            obj.full_name && obj.username != _vm.convertToUsername(obj.full_name)
          )?_c('v-chip',{staticClass:"mx-1",on:{"click":function($event){obj.username = _vm.convertToUsername(obj.full_name);
            _vm.checkUsernameExists(obj.username);}}},[_vm._v(_vm._s(_vm.convertToUsername(obj.full_name)))]):_vm._e()],1):_vm._e()]}}])}),_c('intract-list-sheet',{attrs:{"visible":_vm.showDisabledStaff,"title":"Deactivated Staff"},on:{"close":function($event){_vm.showDisabledStaff = false}},scopedSlots:_vm._u([{key:"list",fn:function(){return [_c('intract-smart-list',{ref:"disabled-staff-smart-list",attrs:{"paginated":"","endpoint":_vm.disabledStaffEndpoint,"item-options":{
          title: 'full_name',
          subtitle: 'username',
          avatar: 'image',
          handleClick: _vm.handleClick,
          appendIcon: 'mdi-arrow-right',
        }}})]},proxy:true}])}),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_staff)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.createStaffSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('image-cropper-dialog',{ref:"cropperDialog",attrs:{"uploadedImage":_vm.uploadedImage},on:{"onCrop":function (croppedImage) {
      _vm.updateObj.image = {
        document: croppedImage,
        file_name: _vm.imageName,
      };
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }