<template>
  <div class="admin-staff-list-page">
    <v-row justify="center">
      <v-col md="6">
        <!-- list of all staff with search -->
        <!-- <intract-smart-list
          ref="admin-staff-smart-list"
          class="mt-4"
          paginated
          :endpoint="staffEndpoint"
          :handle-click="handleClick"
          :item-options="{
            avatar: 'image',
            title: 'full_name',
            subtitle: 'username',
            handleClick: handleClick,
            appendIcon: 'mdi-arrow-right',
          }"
        >
        </intract-smart-list> -->

        <intract-smart-list
          ref="admin-staff-smart-list"
          class="mt-4"
          paginated
          :endpoint="staffEndpoint"
        >
          <template v-slot:list-item="{ item: staffMember }">
                <div class="staff-list-container">
                <v-card class="my-2 mx-2 post-card" elevation="0" @click="handleClick(staffMember)">
                    <v-list-item>
                      <v-list-item-avatar>
                          <v-img :src="staffMember.image"> </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                          <v-list-item-title> {{ staffMember.full_name }} 
                              <v-chip v-if="staffMember.is_admin" x-small class="px-2 ml-1" color="red lighten-4">  
                                Admin
                              </v-chip>
                              <v-chip v-if="staffMember.is_driver" x-small class="px-2 ml-1" color="blue lighten-4">  
                                Driver
                              </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle> {{ staffMember.username }} </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon class="align-self-center mr-n3">
                          <v-icon>mdi-arrow-right</v-icon>
                      </v-list-item-icon> 
                    </v-list-item> 
                </v-card>
                </div>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- intract create staff sheet -->
    <intract-create-edit-sheet
    persistent

      key="staff-create-sheet"
      class="staff-create-sheet"
      title="Create Staff"
      :visible="createStaffSheet.visible"
      @close="
        createStaffSheet.visible = false;
        createStaffSheet.editId = null;
        createStaffSheet.obj.image = null;
      "
      :fields="staffCreationForm"
      submit-button-text="Create"
      :endpoint="endpoints.staffViewSet"
      :edit-id="createStaffSheet.editId"
      :data-object="createStaffSheet.obj"
      :create-success-message="createStaffSheet.createSuccessMessage"
      @objectCreated="staffCreated"
      @updateObject="(obj) => {
        obj.is_faculty = !obj.is_driver
        createStaffSheet.obj = obj
      }"
    >
      <template v-slot:custom-field--image="{ obj }">
        <v-col class="text-center">
          <div>
            <v-avatar size="100px" class="mb-2">
              <v-img
                v-if="!obj.image"
                src="../../../assets/images/default.jpg"
              ></v-img>
              <v-img v-else :src="obj.image.document"></v-img
            ></v-avatar>
          </div>
          <v-btn
            class="my-2"
            color="primary"
            @click="$refs.profileImageField.$refs.input.click()"
            ><v-icon class="mr-3">mdi-image</v-icon
            ><span v-if="!obj.image">Add Image</span
            ><span v-else>Edit Image</span></v-btn
          >
          <v-file-input
            v-show="false"
            class="ma-0 pa-0"
            ref="profileImageField"
            @change="(file) => addProfileImage(file, obj)"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </template>
      <template v-slot:custom-field--username="{ obj }">
        <v-text-field
          v-model="obj.username"
          outlined
          :error-messages="usernameError"
          maxlength="30"
          hide-details="auto"
          :rules="usernameRules"
          @input="checkUsernameExists(obj.username)"
        >
          <template #label>
            Username<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
        <v-col cols="12" v-if="obj.phone || obj.full_name">
          Suggestions:
          <v-chip
            class="mx-1"
            v-if="obj.phone && obj.username != obj.phone"
            @click="
              obj.username = obj.phone;
              checkUsernameExists(obj.username);
            "
            >{{ obj.phone }}</v-chip
          >
          <v-chip
            class="mx-1"
            v-if="
              obj.full_name && obj.username != convertToUsername(obj.full_name)
            "
            @click="
              obj.username = convertToUsername(obj.full_name);
              checkUsernameExists(obj.username);
            "
            >{{ convertToUsername(obj.full_name) }}</v-chip
          >
        </v-col>
      </template>
    </intract-create-edit-sheet>
    <!-- list of disabled staff -->
    <intract-list-sheet
      :visible="showDisabledStaff"
      @close="showDisabledStaff = false"
      title="Deactivated Staff"
    >
      <template v-slot:list>
        <intract-smart-list
          ref="disabled-staff-smart-list"
          paginated
          :endpoint="disabledStaffEndpoint"
          :item-options="{
            title: 'full_name',
            subtitle: 'username',
            avatar: 'image',
            handleClick: handleClick,
            appendIcon: 'mdi-arrow-right',
          }"
        >
        </intract-smart-list>
      </template>
    </intract-list-sheet>
    <!-- create staff fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_staff"
        color="primary"
        @click="createStaffSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        updateObj.image = {
          document: croppedImage,
          file_name: imageName,
        };
      }"
    />

  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import {mapGetters} from "vuex"
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import debounce from "debounce";
import IntractListSheet from "@components/generics/IntractListSheet";

export default {
  name: "AdminStaffList",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
    IntractListSheet,
    IntractCreateEditSheet,
    ImageCropperDialog,
  },
  data() {
    return {
      usernameError: null,
      usernameRules: [
        (v) => !!v || v === 0 || 'Required',
        (v) => /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/.test(v) || 'Invalid',
        (v) => !v ||v.length >= 5 || 'Should be minimum 5 characters',
      ],
      
      showDisabledStaff: false,
      createStaffSheet: {
        visible: false,
        createSuccessMessage: "Staff created successfully!",
        editId: null,
        obj: {
          is_faculty: true,
          is_admin: false,
          is_student: false,
          institution: null,
        },
      },
      uploadedImage: null,
      updateObj: null,
      imageName: null,
    };
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
    // filterFields() {
    //   return {
    //     is_admin: {
    //       model: null,
    //       items: [
    //         { title: "Admin", value: true },
    //         { title: "Faculty", value: false },
    //       ],
    //       label: "Filter by Type",
    //       itemValue: "value",
    //       itemText: "title",
    //       param: "is_admin",
    //     },
    //   };
    // },
    appBarOptions() {
      return {
        actions: [
          {
            id: 1,
            title: "View Deactivated Staff",
            icon: "mdi-account-remove",
            onClick: this.openDisabledStaffSheet,
          },
        ],
      };
    },
    staffEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.staffViewSet, [
        "disabled=false",
        "ordering=full_name",
      ]);
    },
    disabledStaffEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.staffViewSet, [
        "disabled=true",
        "ordering=full_name",
      ]);
    },
    staffCreationForm() {
      return {
        image: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
        full_name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Full Name",
          required: true,
          md: 6,
          max: 255,
        },
        phone: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Phone",
          required: true,
          inputmode: "numeric",
          md: 6,
          input: "phone",
          max: 12,
          customRules: [
            (v) => v == null || v.length >= 10 || "Should be atleast 10 digits",
          ],
          pattern: /^(0|[1-9][0-9]*)$/,
        },
        username: {
          md: 6,
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
        is_admin: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Admin?",
          md: 12,
          helper: "Assign 'Admin' role to staff",
        },
        is_driver: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Driver?",
          md: 12,
          helper: "Assign 'Driver' role to staff",
          hide: !JSON.parse(this.currentInstitution.features).includes(7)
        },
        
      };
    },
  },
  methods: {
    convertToUsername(name) {
      return name.replace(/ /g, ".").trim().toLowerCase();
    },

    checkUsernameExists: debounce(async function (username) {
      if (!username) {
        this.usernameError = null;
        return;
      }
      var url = this.endpoints.checkUsernameExists + `?username=${username}`;
      if (await this.api.call(this.essentials, url)) {
        this.usernameError = "Username Exists";
      } else {
        this.usernameError = null;
      }
    }, 500),

    async addProfileImage(file, obj) {
      if (!file) return;
      this.uploadedImage = await this.Helper.toBase64(file);
      this.imageName = file.name; this.updateObj = obj;
      this.$refs.cropperDialog.initCropper(file.type);
    },
    async handleClick(staff) {
      this.showDisabledStaff = false;
      this.$router.push({
        name: "StaffDetails",
        params: { staffId: staff.id },
      });
    },

    async staffCreated(staff) {
      if (staff.is_driver) return;
      this.$router.push({
        name: "StaffDetails",
        params: { staffId: staff.id },
      });
    },

    async openDisabledStaffSheet() {
      if (this.$refs["disabled-staff-smart-list"])
        this.$refs["disabled-staff-smart-list"].getItems();
      this.showDisabledStaff = true;
    },

    async initListeners() {
      var self = this;
      // removes staff item from list if deleted or disabled
      EventBus.$on("staff_details__staff_deleted", (deletedId) => {
        if (self.$refs["admin-staff-smart-list"])
          self.$refs["admin-staff-smart-list"].removeItem(deletedId);
        if (self.$refs["disabled-staff-smart-list"])
          self.$refs["disabled-staff-smart-list"].removeItem(deletedId);
      });
      // removes staff item from list if deleted or disabled
      EventBus.$on("staff_details__staff_updated", (staff) => {
        if (self.$refs["admin-staff-smart-list"])
          self.$refs["admin-staff-smart-list"].replaceItem(staff.id, staff);
        if (self.$refs["disabled-staff-smart-list"])
          self.$refs["disabled-staff-smart-list"].replaceItem(staff.id, staff);
      });
      // adds new staff to list
      EventBus.$on("staff_details__staff_added", (staff) => {
        if (self.$refs["admin-staff-smart-list"])
          self.$refs["admin-staff-smart-list"].addItem(staff, true);
        if (self.$refs["disabled-staff-smart-list"])
          self.$refs["disabled-staff-smart-list"].removeItem(staff.id);
      });
    },

    async onRefresh() {
      this.$refs["admin-staff-smart-list"].getItems();
      this.$refs["disabled-staff-smart-list"].getItems();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name != "StaffDetails") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "AdminStaffList");
    }
    next();
  },
  created() {
    this.createStaffSheet.obj.institution = this.currentInstitution.id;
    this.initListeners();
  },
};
</script>